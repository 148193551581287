<template>
   <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-body class="p-0"> 
    <!-- User Info: Input Fields -->
      <b-form v-on:submit.prevent="onUpdate">

        <b-row>
          <!-- Field: Full Name -->
          <b-col cols="12" md="3">
            <b-form-group label="Date" label-for="Date">
              <validation-provider
                #default="{ errors }"
                name="date"
                rules="required"
              >
                <b-form-datepicker
                    id="datepicker-dateformat1"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    v-model="date"
                ></b-form-datepicker>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col cols="12" md="3">
            <b-form-group label="Clock IN" label-for="clock_in">
              <validation-provider
                #default="{ errors }"
                name="clock_in"
                rules="required"
              >
                    <b-form-timepicker
                v-model="clock_in"
                ></b-form-timepicker>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group label="Date" label-for="Date">
              <validation-provider
                #default="{ errors }"
                name="out_date"
                rules="required"
              >
                <b-form-datepicker
                    id="datepicker-dateformat2"
                    :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                    locale="en"
                    v-model="out_date"
                ></b-form-datepicker>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col cols="12" md="3">
            <b-form-group label="Clock OUT" label-for="clock_in">
              <validation-provider
                #default="{ errors }"
                name="clock_out"
                rules="required"
              >
              <b-form-timepicker
                v-model="clock_out"
                ></b-form-timepicker>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Action Buttons -->
        <b-button
          type="submit"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          
        >
          Save Changes
        </b-button>

       
      </b-form>
      </b-card-body>
      </b-card>
      </b-col>
      </b-row>
</template>
    
    <script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormTimepicker,
  BFormDatepicker,
} from "bootstrap-vue";
import { required, min, confirmed } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { serverUri } from "@/config";
import router from "@/router";
import { getUserData } from "@/auth/utils";
import flatPickr from 'vue-flatpickr-component'
import moment  from "moment";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    flatPickr
  },
  data() {
    return {
      date: "",
      out_date: "",
      userId: getUserData().id,
      companyId: getUserData().company_id,
      clockId: router.currentRoute.params.id,
      clock_in: "",
      clock_out: '',
    };
  },
  mounted() {
    this.getClockHistory();
  },
  computed: {},
  props: {},
  methods: {
    getClockHistory() {
    const user_id = this.userId;
      this.$http
        .post(`${serverUri}/driver/clock-history-details`, {
          main_id: router.currentRoute.params.id,
          driver_id: user_id,
          company_id:this.companyId
        })
        .then((response) => {
          this.data = response.data;
          if (response.data.status == true) 
          {
            const Response  = response.data.data[0];
            this.date       = Response.date;
            this.out_date   = moment(Response.clock_out).format("YYYY-MM-DD");
            this.clock_in   = moment(Response.clock_in).format("HH:mm");
            this.clock_out  = moment(Response.clock_out).format("HH:mm");
          }
        });
    },
    onUpdate()
    {

        this.$swal({
                        title: "Are you sure to update clock history?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Save",
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {

                        this.$http
                            .post(`${serverUri}/driver/update-clock-history`, {
                            main_id: router.currentRoute.params.id,
                            driver_id: this.userId,
                            company_id:this.companyId,
                            date: this.date,
                            out_date: this.out_date,
                            clock_in: this.clock_in,
                            clock_out: this.clock_out,

                            })
                            .then((response) => {
                            this.data = response.data;
                            if (response.data.status == true) 
                            {
                                this.$router.push({
                                    name: "clock-history",
                                });
                                // console.log(response.data);
                            }
                            });
                    });


    },
    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    validationForm() {
    //   this.$refs.simpleRules.validate().then((success) => {
    //     if (success) {
    //       // eslint-disable-next-line
    //       alert("password reset!");
    //     }
    //   });
    },
   
  },
  created() {
    // axios.get(`${serverUri}/users/getpermission`).then(res => {
    //     res.data.map(item => {
    //         this.options.push({ value: item.action, text: item.action.split('manage-')[1] })
    //     })
    // })
  },
};
</script>
    
    <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.edit-header {
  display: flex;
  align-items: center;
}
</style><style>
.permission {
  margin: 10px 0;
}

.permission div {
  margin: 2px 0;
}
</style>
    